import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutTutorielFR from "../../composants/layout/fr/LayoutTutorielFR"
import Lien from "../../composants/navigation/Lien"
import Section from "../../composants/layout/Section"

import { obtenirPage } from "../../js/client-es/utils"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pagePrevisionsCouvertureNuageuse: allPagesCompilationYaml(
      filter: { code: { eq: "previsionsCouvertureNuageuse" } }
    ) {
      ...FragmentPageYaml
    }
  }
`

export default function CouvertureNuageuse() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pagePrevisionsCouvertureNuageuse")

  return (
    <LayoutTutorielFR page={page}>
      <Section titre="Avant-propos">
        <p>
          Vous trouverez ci-dessous l’évolution de la couverture nuageuse prévue{" "}
          <strong>par le modèle météo français ARPEGE</strong> sur la France
          pour les <strong>3 prochains jours</strong>. Il y a une image toutes
          les <strong>6 heures</strong>.
        </p>
        <p>
          Les zones <strong>noires</strong> correspondent aux zones{" "}
          <strong>sans nuages </strong>: le ciel est parfaitement clair et il
          est possible d’observer. Pour des prévisions fiables, croisez les
          prévisions issues de modèles différents.
        </p>
      </Section>
      <Section titre="Prévisions à 3 jours">
        <div className="conteneur-image-couverture-nuageuse">
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-6-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-12-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-18-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-24-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-30-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-36-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-42-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-48-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-54-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-60-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-66-0.png?" />
          <img src="http://modeles7.meteociel.fr/modeles/arpege/arpege-24-72-0.png?" />
        </div>
      </Section>
    </LayoutTutorielFR>
  )
}
